<template>
  <!-- main-area -->
  <section class="chatbox main-area">
    <!-- right-body -->
    <!-- message-area -->
    <div class="message-area">
      <div class="msg-search">
        <input
          type="text"
          v-model="searchValue"
          class="form-control"
          placeholder="Search"
          v-on:keyup="search"
        />
        <img
          class="img-fluid sea-icon"
          src="../../assets/img/search.svg"
          alt=""
        />
      </div>
      <div class="add-msg">
        <a
          href="#"
          data-dismiss="modal"
          aria-label="Close"
          data-toggle="modal"
          data-target="#Modal2"
          class="room"
          ><img class="img-fluid" src="../../assets/img/room.svg" alt=""
        /></a>
      </div>

      <div
        class="chat-list simplebar"
        id="myElement7"
        style="overflow-y: scroll"
      >
        <div v-for="(lead, index) in leads" :key="index">
          <div
            class="media"
            :class="'media_' + lead.l_id"
            @click="getmessages(lead)"
          >
            <img
              v-if="lead.c_img"
              :src="lead.c_img"
              class="align-self-start mr-3"
              alt=""
            />
            <img
              v-else
              src="../../assets/img/profile.svg"
              class="align-self-start mr-3"
              alt=""
            />
            <span class="online"></span>
            <div class="media-body">
              <h5>
                {{ lead.c_first_name }} {{ lead.c_last_name }}
                <!--<span>15 May 2021</span>-->
              </h5>
              <!-- <p>Looking good</p> -->
              <div class="listbottom" :id="'marknewmessg' + lead.l_id">
                <a class="fb" href="#">{{ lead.ls_name }}</a>
                <a
                  class="fb"
                  :class="'count' + lead.l_id"
                  href="#"
                  v-if="lead.count > 0"
                  >{{ lead.count }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <infinite-loading @infinite="getActiveleads"></infinite-loading>
      </div>
    </div>
    <!-- message-area -->

    <!-- chatbox-area -->
    <div class="chatbox-area">
      <div class="adf">
        <div class="msg-head" v-if="lead.c_first_name">
          <div class="media">
            <span class="chat-icon"
              ><i @click="hideMessageBox" class="fa fa-angle-left"></i
            ></span>
            <img
              v-if="lead.c_img"
              :src="lead.c_img"
              class="align-self-start mr-3"
              alt="..."
            />
            <img
              v-else
              src="../../assets/img/profile.svg"
              class="align-self-start mr-3"
              alt="..."
            />
            <div class="media-body">
              <h5 class="mt-0">
                <a style="color: black" :href="'/lead-detail/' + lead.l_id"
                  >{{ lead.c_first_name }} {{ lead.c_last_name }}</a
                >
              </h5>
            </div>
          </div>
        </div>
        <div class="msg-setting" v-if="lead.c_first_name">
          <ul>
            <li>
              <div class="navbar">
                <div class="dropdown">
                  <a
                    @click="addvehicleModal"
                    class="msgsct active"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    ><img
                      class="img-fluid csdd"
                      src="../../assets/img/vec.svg"
                      alt=""
                    />
                    Vehicles
                  </a>
                </div>
              </div>
            </li>
            <li>
              <div class="navbar">
                <div class="dropdown">
                  <a
                    @click="opentradeModal"
                    class="msgsct"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    Trade in
                    <img
                      class="img-fluid"
                      src="../../assets/img/plus.svg"
                      alt=""
                  /></a>
                </div>
              </div>
            </li>
            <li>
              <div class="navbar">
                <div class="dropdown">
                  <a
                    class="msgsct dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    >Request</a
                  >
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a
                      v-if="dealer_detail.dl_creditapp"
                      @click="request('CreditApp')"
                      class="dropdown-item credit"
                      >Credit App</a
                    >
                    <a
                      v-if="dealer_detail.dl_tradein"
                      @click="request('Trade')"
                      class="dropdown-item credit"
                      >Trade in</a
                    >
                    <a
                      v-if="dealer_detail.dl_facebookreview"
                      @click="request('Facebook')"
                      class="dropdown-item credit"
                      >Facebook Review</a
                    >
                    <a
                      v-if="dealer_detail.dl_googlereview"
                      @click="request('Google')"
                      class="dropdown-item credit"
                      >Google Review
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="navbar">
                <div class="dropdown">
                  <a
                    class="msgsct-user dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="modal"
                    data-target="#leadAssign"
                    aria-haspopup="true"
                    aria-expanded="true"
                    ><img
                      class="img-fluid"
                      src="../../assets/img/profile.svg"
                      alt=""
                    />
                    <span v-on:click="leadAssignedTo" v-if="assig_usres_str">{{
                      assig_usres_str
                    }}</span></a
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        class="message-content"
        id="CommunicationSmsSection"
        v-if="lead.chat == 'phone'"
        style="height: 68%"
      >
        <div class="msg-body message-contentss">
          <ul>
            <div v-for="message in messages" :key="message.id">
              <li
                :class="
                  message.msg_action == 'send' ||
                  message.msg_action == 'pending'
                    ? 'repaly'
                    : 'sender'
                "
              >
                <span class="send-time" style="display: block">
                  {{ message.msg_date }} {{ message.msg_time }}</span
                >
                <p v-if="message.msg_body" v-html="message.msg_body"></p>
                <VuePureLightbox
                  v-if="message.image"
                  :thumbnail="message.image"
                  :images="[message.image]"
                />
              </li>
            </div>
            <div id="append_chat"></div>
          </ul>
        </div>
      </div>

      <div
        class="message-content"
        id="CommunicationFBSection"
        v-else
        style="height: 68%"
      >
        <div class="msg-body message-contentss">
          <ul>
            <div v-for="message in FbMessages" :key="message.id">
              <li
                :class="
                  message.msg_action == 'send' ||
                  message.msg_action == 'pending'
                    ? 'repaly'
                    : 'sender'
                "
              >
                <span class="send-time" style="display: block">
                  {{ message.msg_date }}
                  {{ message.msg_time }}</span
                >
                <p v-html="message.message">}</p>
                <VuePureLightbox
                  v-if="message.image"
                  :thumbnail="message.image"
                  :images="[message.image]"
                />
              </li>
            </div>
            <div id="append_chat"></div>
          </ul>
        </div>
      </div>

      <div class="send-box">
        <form @submit.prevent="addChat" enctype="multipart/form-data">
          <span style="color: red; position: relative; bottom: 10px">{{
            message_error
          }}</span>
          <input
            v-if="this.lead.chat == 'facebook'"
            type="text"
            class="form-control"
            id="message"
            placeholder="Write message…"
          />
          <input
            v-else
            type="text"
            :disabled="
              (lead.c_is_first_sms == 0 && lead.c_sms_permission == 0) ||
              disableBtn
            "
            class="form-control"
            id="message"
            placeholder="Write message…"
          />
        </form>
        <div class="send-btns">
          <div class="row">
            <div class="col-md-7">
              <div class="attach">
                <div class="button-wrapper">
                  <span class="label">
                    <img
                      class="img-fluid"
                      src="../../assets/img/aad.svg"
                      alt=""
                    />
                    attached file </span
                  ><input
                    type="file"
                    name="upload"
                    id="upload"
                    class="upload-box"
                    placeholder="Upload File"
                    v-on:change="onFileChange"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="email-sends" v-if="this.lead.chat == 'facebook'">
                <a @click="addChat" :disabled="btnLoader" class="smssend">
                  <span v-if="btnLoader"
                    ><span class="spinner-grow spinner-grow-sm"></span>
                    Loading...</span
                  >
                  <span v-if="!btnLoader"
                    ><img
                      class="img-fluid"
                      src="../../assets/img/sms-sned.svg"
                      alt=""
                    />
                    Send</span
                  >
                </a>
              </div>
              <div class="email-sends" v-else>
                <button
                  @click="addChat"
                  :disabled="
                    (lead.c_is_first_sms == 0 && lead.c_sms_permission == 0) ||
                    disableBtn
                  "
                  class="smssend"
                >
                  <span v-if="btnLoader"
                    ><span class="spinner-grow spinner-grow-sm"></span>
                    Loading...</span
                  >
                  <span v-if="!btnLoader">
                    <img
                      class="img-fluid"
                      src="../../assets/img/sms-sned.svg"
                      alt=""
                    />
                    Send</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- chatbox-area -->

    <div
      class="modal fade"
      id="addvehicleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Add Trade"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Vehicle</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="col-md-12">
            <div class="detais-search">
              <div class="form-group Vehicle">
                <autocomplete
                  ref="autocomplete"
                  placeholder="Search Inventory"
                  input-class="form-control border-0"
                  :source="
                    'https://autolinkme.com/webservices/crm/inventory?id=' +
                    $storage.get('auth').user.dealer_autolink_id +
                    '&limit=20&offset=0&status=active&search_by=value&search_string='
                  "
                  results-property="data"
                  :results-display="formattedAutocompleteDisplay"
                  @selected="selectedVehicle"
                  @noResults="addVehicleOnNoResult"
                  @results="addVehicleOnNoResult"
                  :showNoResults="false"
                  @clear="clear"
                >
                </autocomplete>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="vehicleDetailModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="vehicleDetailModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <a class="delas-colse close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Closex.svg"
                alt=""
            /></span>
          </a>
          <div class="modal-body Intersets-modal">
            <div class="row">
              <div class="col-lg-7">
                <div class="exzoom" id="exzoom">
                  <div class="exzoom_img_box">
                    <ul class="exzoom_img_ul">
                      <li>
                        <img
                          class="img-fluid car-img"
                          :src="v_detail.v_image"
                          alt=""
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 pl-0">
                <div class="details-modal">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <h5 style="color: #000">
                            {{ v_detail.v_year }}
                            {{ v_detail.v_make }}
                            {{ v_detail.v_model }}
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>Stock#</td>
                        <td>{{ v_detail.v_stock_no }}</td>
                      </tr>
                      <tr>
                        <td>Mileage</td>
                        <td>{{ v_detail.v_mileage }}</td>
                      </tr>
                      <tr>
                        <td>Color</td>
                        <td>
                          <!--<span class="black"></span>-->{{
                            v_detail.v_ext_color
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Ext</td>
                        <td>{{ v_detail.v_ext_color }}</td>
                      </tr>
                      <tr>
                        <td>Interior</td>
                        <td>{{ v_detail.v_int_color }}</td>
                      </tr>
                      <tr>
                        <td>Engine</td>
                        <td>{{ v_detail.v_engine }}</td>
                      </tr>

                      <tr>
                        <td>MSRP</td>
                        <td>
                          {{ v_detail.v_msrp ? v_detail.v_msrp : "NA" }}
                        </td>
                      </tr>

                      <tr>
                        <td>Selling Price</td>
                        <td>${{ v_detail.v_price | formatPrice }}</td>
                      </tr>

                      <tr>
                        <td>Special Price</td>
                        <td v-if="v_detail.v_special_price">
                          ${{ v_detail.v_special_price | formatPrice }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12">
                <div class="notes-con" v-if="v_detail.v_description">
                  <h3>Description</h3>
                  <p v-html="v_detail.v_description"></p>
                </div>
              </div>
              <div class="col-12 text-center mt-3">
                <button
                  class="rem-btn"
                  style="display: initial"
                  v-on:click="updateVOI(v_detail)"
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="addVehicle"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Add Trade"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Vehicle</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="addVehicle">
            <div class="modal-body">
              <div class="add-member add-trades">
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="t_years">Type <span>*</span></label>
                    <select
                      class="form-control"
                      required
                      v-model="vehicle.type"
                    >
                      <option value="Used">Used</option>
                      <option value="NULL">New</option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="t_years">Year</label>
                    <input
                      type="number"
                      class="form-control"
                      id="t_years"
                      v-model="vehicle.v_year"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Make <span>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      required
                      v-model="vehicle.v_make"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Model <span>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      required
                      v-model="vehicle.v_model"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Trim</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="vehicle.trim"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>Style</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="vehicle.color"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Mileage</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="vehicle.v_mileage"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a
                href="#"
                data-dismiss="modal"
                aria-label="Close"
                class="add-btn"
                >Cancel</a
              >
              <button type="submit" class="rem-btn">Add Vehicle</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal fade" id="leadAssign" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Assign Lead</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="assignToUser">
            <div class="modal-body">
              <div class="add-member add-lead">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <multi-select
                      :searchable="true"
                      id="a_users"
                      :alreadySelected="assign.to"
                      textColumn="text"
                      placeholder="Select users"
                      v-model="assign.to"
                      :options="allEmployees"
                      label="Users"
                    ></multi-select>

                    <!-- <multi-select :searchable="true" id="a_users" :alreadySelected="assign.to" textColumn="text" placeholder="Select users" v-model="assign.to" :options="allEmployees" label="Users"></multi-select> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a href="#" class="add-btn" data-dismiss="modal"
                ><img
                  class="img-fluid"
                  src="../../assets/img/22. Delete.svg"
                  alt=""
                />
                Cancel</a
              >
              <button type="submit" class="rem-btn">
                <img
                  class="img-fluid"
                  src="../../assets/img/23. Close.svg"
                  alt=""
                />
                Assign
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="addTradeModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Add Trade"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Trade</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="addTrade">
            <div class="modal-body">
              <div class="add-member add-trades">
                <div class="form-group row mb-4">
                  <label class="col-sm-12 col-form-label">VIN</label>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.vin"
                    />
                  </div>
                  <div class="col-sm-4">
                    <a
                      v-on:click.stop.prevent="vinDecode('trade')"
                      :disabled="btnLoader"
                      href="#"
                      class="rem-btn"
                      style="width: 100%"
                      ><span v-if="btnLoader" style="width: 100%"
                        ><span class="spinner-grow spinner-grow-sm"></span>
                        Wait...</span
                      ><span
                        style="width: 100%; text-align: center"
                        v-if="!btnLoader"
                      >
                        Decode</span
                      ></a
                    >
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="t_years">Years <span>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      id="t_years"
                      v-model="trade_.years"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Make <span>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.make"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Model</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.model"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Trim / Style</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.style"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Color</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.color"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Mileage <span>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.mileage"
                      required
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>ACV</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.acv"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Allowance</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.allowance"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label>Notes</label>
                  <textarea
                    class="form-control"
                    rows="2"
                    v-model="trade_.notes"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label>Payoff</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="trade_.payoff"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a
                href="#"
                data-dismiss="modal"
                aria-label="Close"
                class="add-btn"
                >Cancel</a
              >
              <button type="submit" class="rem-btn">Save trade</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade newroom-modal"
      id="Modal2"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Filter Leads
            </h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <div class="new-room">
              <form class="test">
                <div class="form-group">
                  <p style="color: #3151a1">Sort</p>
                  <select
                    class="form-control"
                    v-model="filtervalue"
                    @change="filterLeads"
                  >
                    <option value="sent">Sent</option>
                    <option value="received">Received</option>
                    <option value="new">New</option>
                  </select>
                </div>
                <div class="join">
                  <!-- <button type="submit" class="rem-btn">Filter</button> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- main-area -->
</template>
<script>
import axios from "axios";
import VueDaval from "vue-daval";
import $ from "jquery";
import InfiniteLoading from "vue-infinite-loading";
import Autocomplete from "vuejs-auto-complete";
import MultiSelect from "../helpers/MultiSelect";
import VuePureLightbox from "vue-pure-lightbox";
import "vue-pure-lightbox/dist/VuePureLightbox.css";
export default {
  data() {
    return {
      dealer_detail: {},
      filter: false,
      filtervalue: "sent",
      loadmore: true,
      message_error: "",
      file: "",
      allEmployees: [],
      assign: {
        to: [],
        lead_id: 0,
        alreadyAssigned: this.alreadyAssignedUsers,
      },
      alreadyAssignedUsers: [],
      assig_usres_str: "",
      leads: [],
      disableBtn: false,
      lead: {
        chat: "",
      },
      FbMessages: [],
      messages: [],
      searchValue: "",
      vehicle: {},
      novehicleresult: 0,
      v_detail: {},
      voi: {},
      count: [],
      btnLoader: false,
      trade_: {
        years: "",
        make: "",
        model: "",
        mileage: "",
        color: "",
        style: "",
        acv: "",
      },
      moreExists: false,
      nextpage: 1,
    };
  },
  components: {
    Autocomplete,
    MultiSelect,
    InfiniteLoading,
    VuePureLightbox,
  },
  mixins: [VueDaval],
  vdRules: {
    trade_: {
      years: { required: true },
      make: { required: true },
    },
  },
  created() {
    this.getAllEmployees();
    this.getDealerDetail();
    //this.getActiveleads();
    let vm = this;
    this.$root.$on("action", function (action, data) {
      if (action == "fbnotification") {
        if (data.message) {
          if (
            data.type == "sms" &&
            data.receiver == this.$storage.get("auth").user.email
          ) {
            console.log("repeat");
            setTimeout(function () {
              vm.scrollToEndSMSContainer();
              $("#text").addClass("tab-pane");
            }, 1000);
            // vm.messages.push({ msg_body: data.message });
            //vm.getSMS();
          }
          if (
            data.type == "facebook" &&
            data.receiver == this.$storage.get("auth").user.email
          ) {
            vm.FbMessages.push({ message: data.message });
            setTimeout(function () {
              vm.scrollToEndFbContainer();
            }, 1000);
          }
        }
        //vm.nextpage = 1;
        // vm.getFBMessages();
        // vm.getSMS();
        //vm.getActiveleads();
      }
    });
  },

  methods: {
    getDealerDetail() {
      axios
        .get("get_dealer_detail", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.dealer_detail = res.data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showfilter() {
      this.filter = !this.filter;
    },
    hideMessageBox() {
      $(".chatbox-area").removeClass("showbox");
    },
    filterLeads() {
      if (this.filtervalue) {
        this.btnLoader = true;
        axios
          .get("filter/leads/" + this.filtervalue, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.leads = [];
              res.data.leads.data.forEach((data) => {
                this.leads.push(data);
              });
              $("#Modal2").modal("hide");
              this.btnLoader = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.btnLoader = false;
          });
      }
    },
    search() {
      if (this.searchValue) {
        axios
          .get("searchleads/" + this.searchValue, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              console.log("seach");
              console.log(res.data);
              console.log(res.data.lead);
              this.leads = res.data.lead;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.leads = [];
        this.nextpage = 1;
        this.getActiveleads();
      }
    },
    scrollToEndSMSContainer: function () {
      var container = this.$el.querySelector("#CommunicationSmsSection");
      container.scrollTop = container.scrollHeight;
    },
    scrollToEndFbContainer: function () {
      var container = this.$el.querySelector("#CommunicationFBSection");
      container.scrollTop = container.scrollHeight;
    },
    onFileChange(e) {
      console.log(e.target.files[0]);
      this.file = e.target.files[0];
      this.addChat();
    },
    request(val) {
      axios
        .get("get/request", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.status) {
            if (val == "CreditApp") {
              $("#message").val(data.data.dl_creditapp);
            }
            if (val == "Facebook") {
              $("#message").val(data.data.dl_facebookreview);
            }
            if (val == "Google") {
              $("#message").val(data.data.dl_googlereview);
            }
            if (val == "Trade") {
              $("#message").val(data.data.dl_tradein);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    assignToUser() {
      this.assign.alreadyAssigned = this.alreadyAssignedUsers;
      this.assign.lead_id = this.lead.l_id;
      this.$root.$emit("action", "clearmultiselct");
      this.$root.$emit("action", "clearmultiselct");
      axios
        .post("lead_assign", this.assign, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.alreadyAssignedUsers = res.data.new_assigned;

            this.$root.$emit("action", "new_activity");
            let vm = this;

            this.lead.assigned_users = "";
            this.lead.assigned_user_str = "";

            $.each(this.assign.to, function (key, value) {
              if (key != 0) {
                vm.lead.assigned_users += ",";
                vm.lead.assigned_user_ids += ",";
              }
              vm.lead.assigned_users += value.id + "|" + value.text;
              vm.lead.assigned_user_str += value.text;
            });
            this.leadAssignedTo();
            $("#leadAssign").modal("hide");
            if (res.data.noti_emails.length > 0) {
              $.each(res.data.noti_emails, function (key, value) {
                vm.$socket.emit("send_noti", { receiver: value });
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllEmployees() {
      axios
        .get("all_employees/2", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            if (data.success.length > 0) {
              var dd = [];
              $.each(data.success, function (key, value) {
                dd.push({ id: value.id, text: value.name });
              });
              this.allEmployees = dd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    leadAssignedTo() {
      this.assig_usres_str = "";
      this.alreadyAssignedUsers = [];
      this.$root.$emit("action", "clearmultiselct");
      axios
        .get("get/assignleads/" + this.lead.l_id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            var data = res.data.success.assigned_user_str.split(",");
            this.assig_usres_str = data[0];
            this.assign.lead_id = this.lead.l_id;
            if (
              res.data.success.assigned_users !== undefined &&
              res.data.success.assigned_users != "undefined" &&
              res.data.success.assigned_users != null &&
              res.data.success.assigned_users != ""
            ) {
              var au = res.data.success.assigned_users.split(",");
              console.log(au);
              let vm = this;
              vm.alreadyAssignedUsers = [];
              vm.assign.to.splice(0);
              $.each(au, function (i, v) {
                var aua = v.split("|");
                if (aua) {
                  vm.alreadyAssignedUsers.push({ id: aua[0], text: aua[1] });
                  vm.assign.to.push({ id: aua[0], text: aua[1] });
                }

                //vm.assign.to.push({id:au_ids[i],text:v})
              });
              //console.log(vm.assign.to)
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addvehicleModal() {
      $("#addvehicleModal").modal("show");
    },
    addVehicle() {
      axios
        .post(
          "add/vehicle",
          { vehicle: this.vehicle, lead_id: this.$route.params.id },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.status) {
            $("#addVehicle").modal("hide");
            $(".autocomplete_no_results").remove();
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // this.updateVOI(this.vehicle);
    },
    addVehicleOnNoResult() {
      if ($(".autocomplete__results .autocomplete_no_results").length == 0) {
        $(".autocomplete__results").append(
          '<li class="autocomplete__results__item autocomplete_no_results">Add Vehicle</li>'
        );
      }
    },
    clear() {
      $(".autocomplete_no_results").remove();
    },
    opentradeModal() {
      $("#addTradeModal").modal("show");
    },
    addTrade() {
      //console.log(this.trade_);
      this.trade_.lead_id = this.lead.l_id;
      this.$vd.trade_
        .$validate()
        .then(() => {
          axios
            .post("add_trade", this.trade_, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                $("#addTradeModal").modal("hide");
                this.trade_ = {};
                this.getTrades();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    vinDecode(type) {
      if (
        (this.trade_.vin != "" &&
          this.trade_.vin != undefined &&
          this.trade_.vin != "undefined" &&
          this.trade_.vin != null) ||
        (this.vehicle.v_vin != "" &&
          this.vehicle.v_vin != undefined &&
          this.vehicle.v_vin != "undefined" &&
          this.vehicle.v_vin != null)
      ) {
        this.btnLoader = true;
        if (type == "trade") {
          axios
            .get(
              "https://autolinkme.com/webservices/crm/vin_decoder?id=" +
                this.$storage.get("auth").user.dealer_id +
                "&status=active&vin=" +
                this.trade_.vin
            )
            .then((res) => {
              if (res.data.status) {
                let v_data = res.data.data;
                this.trade_.years = v_data.Year;
                this.trade_.make = v_data.Make;
                this.trade_.model = v_data.Model;
                //this.trade_.mileage = : v_data.Year
                //this.trade_.color = : v_data.Year
                this.trade_.style = v_data.Trim;

                console.log(res.data.data);
              }
              this.btnLoader = false;
            })
            .catch((err) => {
              console.log(err);
              this.btnLoader = false;
            });
        } else if (type == "vehicle") {
          axios
            .get(
              "https://autolinkme.com/webservices/crm/vin_decoder?id=" +
                this.$storage.get("auth").user.dealer_id +
                "&status=active&vin=" +
                this.vehicle.v_vin
            )
            .then((res) => {
              if (res.data.status) {
                let v_data = res.data.data;
                this.vehicle.v_year = v_data.Year;
                this.vehicle.v_make = v_data.Make;
                this.vehicle.v_model = v_data.Model;
                //this.trade_.mileage = : v_data.Year
                //this.trade_.color = : v_data.Year
                this.vehicle.style = v_data.Trim;

                console.log(res.data.data);
              }
              this.btnLoader = false;
            })
            .catch((err) => {
              console.log(err);
              this.btnLoader = false;
            });
        }
      }
    },
    addChat() {
      this.btnLoader = true;
      var message = $("#message").val();
      if (this.lead.chat == "phone") {
        if (message == "" && this.file == "") {
          this.$toast.open({
            position: "top-right",
            message:
              "Message cannot be empty, must provide valid attachment or text",
            type: "info",
          });
          this.btnLoader = false;
          return false;
        }
        this.message_error = "";
        const config = {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        };
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("body", message);
        formData.append(
          "c_name",
          this.lead.c_first_name + " " + this.lead.c_last_name
        );
        formData.append("customer_id", this.lead.c_id);
        formData.append("dealer_id", this.lead.l_dealer_id);
        formData.append("isFirstSms", this.lead.c_is_first_sms);
        formData.append("smsPermission", this.lead.c_sms_permission);
        formData.append("lead_id", this.lead.l_id);
        formData.append("phone", this.lead.c_phone);

        axios
          .post("/sendSMS", formData, config)
          .then((res) => {
            if (res.data.error) {
              this.$toast.open({
                position: "top-right",
                message: res.data.error,
                type: "info",
              });
              this.btnLoader = false;
              return false;
            }
            this.file = "";
            this.btnLoader = false;
            this.getmessages(this.lead);
            if (this.lead.l_status < 2) {
              this.updateLeadStatus(2);
            }
            this.$root.$emit("action", "new_activity");
            this.$store.commit(
              "updateTouchesCount",
              this.$store.state.touches + 1
            );
            this.$root.$emit(
              "action",
              "update_touch_status",
              res.data.lead_touch_resp_time
            );
            this.lead.c_is_first_sms = 0;
            $("#message").val("");
            if (res.data.perm_update == 1) {
              this.lead.c_sms_permission = 1;
              this.disableBtn = false;
            }
          })
          .catch((error) => {
            this.btnLoader = false;
            console.log(error);
            //alert(error);
          });
      } else {
        if (this.file || message != "") {
          this.message_error = "";
          const config = {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          };
          let formData = new FormData();
          formData.append("cus_id", this.lead.c_id);
          formData.append("message", message);
          formData.append("file", this.file);
          formData.append("lead_id", this.lead.l_id);
          axios
            .post("/sendFBMessage", formData, config)
            .then((res) => {
              console.log(res);
              this.file = "";
              this.btnLoader = false;
              const data = res.data;
              if (data.success) {
                this.getFBMessages();
                $("#message").val("");
                this.$root.$emit(
                  "action",
                  "update_touch_status",
                  data.lead_touch_resp_time
                );
              }
              if (data.success == false) {
                this.$toast.open({
                  position: "top-right",
                  message: data.message,
                  type: "info",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.btnLoader = false;
              this.$toast.open({
                position: "top-right",
                message: "Message reply time is ended",
                type: "info",
              });
            });
        } else {
          this.message_error =
            "Message cannot be empty, must provide valid attachment or text";
        }

        // axios
        //   .post(
        //     "sendFBMessage",
        //     {
        //       cus_id: this.lead.c_id,
        //       message: message,
        //     },
        //     {
        //       headers: {
        //         Authorization: "Bearer " + this.$storage.get("auth").token,
        //       },
        //     }
        //   )
        //   .then((res) => {
        //     if (res.data) {
        //       const data = res.data;
        //       this.btnLoader = false;
        //       if (data.success) {
        //         this.getFBMessages();
        //         $("#message").val("");
        //       }
        //     }
        //   })
        //   .catch((err) => {
        //     this.btnLoader = false;
        //     console.log(err);
        //   });
      }
    },
    getmessages(lead) {
      this.lead = {};
      this.lead.c_first_name = "";
      this.lead.c_last_name = "";
      this.lead = lead;
      $(".media").removeClass("active");
      $(".media_" + lead.l_id).addClass("active");
      $(".chatbox-area").addClass("showbox");
      this.lead = lead;

      $("#marknewmessg" + lead.l_id + " " + ".mark ").remove();
      $(".count" + lead.l_id).remove();
      if (this.lead.facebook_receiver_id && this.lead.facebook_messenger_id) {
        this.getFBMessages();
        this.lead.chat = "facebook";
      } else {
        this.lead.chat = "phone";
        axios
          .get("getSMS/" + this.lead.l_customer_id + "/" + "chat", {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              const data = res.data;
              this.messages = data.success;
              let vm = this;
              setTimeout(function () {
                vm.scrollToEndSMSContainer();
                $("#text").addClass("tab-pane");
              }, 1000);
              vm.leadAssignedTo();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    updateLeadStatus(status) {
      axios
        .post(
          "update_lead_status",
          { status: status, lead_id: this.lead.l_id },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.lead.l_status = 2;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFBMessages() {
      axios
        .post(
          "getFbMessages",
          {
            cus_id: this.lead.c_id,
            module: "chat",
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            const data = res.data;
            if (data.success) {
              // $("#myElement8")
              //   .stop()
              //   .animate({ scrollTop: $("#myElement8")[0].scrollHeight }, 1000);
              this.FbMessages = data.success;
              let vm = this;
              setTimeout(function () {
                vm.scrollToEndFbContainer();
              }, 1000);
              vm.leadAssignedTo();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSMS() {
      //console.log(this.lead_detail)
      axios
        .get("getSMS/" + this.lead.c_id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.messages = data.success;
            this.scrollToEndSMSContainer();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getActiveleads($state) {
      axios
        .get("get/active/leads", {
          params: {
            page: this.nextpage,
          },
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.status) {
            if (res.data.leads.current_page < res.data.leads.last_page) {
              this.moreExists = true;
              this.nextpage = res.data.leads.current_page + 1;
            } else {
              $state.complete();
              this.moreExists = false;
            }
            res.data.leads.data.forEach((data) => {
              this.leads.push(data);
              //this.lead = data;
              if (data.facebook_messenger_id && data.facebook_receiver_id) {
                this.lead.chat = "facebook";
              } else {
                this.lead.chat = "phone";
              }
              // this.lead = data;
            });
            // this.count = res.data.count;
            // this.leadAssignedTo();
            $state.loaded();
          } else {
            $state.complete();
          }
        });

      //  axios
      // .get("get/active/leads?page"+nextpage, {
      //   headers: {
      //     Authorization: "Bearer " + this.$storage.get("auth").token,
      //   },
      // })
      // .then((res) => {

      //   if (res.data.status) {
      //           if (
      //       res.data.leads.current_page <
      //       res.data.leads.last_page
      //     ) {

      //       this.moreExists = true;
      //       this.nextpage = res.data.leads.current_page + 1;
      //     } else {
      //       this.moreExists = false;
      //     }
      //     res.data.leads.data.forEach((data) => {
      //       this.leads.push(data);
      //       console.log(data);
      //       this.lead = data;
      //       if (data.facebook_messenger_id && data.facebook_receiver_id) {
      //         this.lead.chat = "facebook";
      //       } else {
      //         this.lead.chat = "phone";
      //       }
      //       this.lead =data;
      //     });

      //     this.count = res.data.count;
      //     this.leadAssignedTo();
      //   }
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
    },
    getNewleads() {
      axios
        .get("get/new/leads", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res);
          this.leads = res.data.leads;
          this.count = res.data.count;
          this.leadAssignedTo();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formattedAutocompleteDisplay(result) {
      return (
        result.year +
        " " +
        result.make +
        " " +
        result.model +
        " - " +
        result.vin
      );
    },
    selectedVehicle(result) {
      //console.log(result.selectedObject)
      this.v_detail = {};
      var v = result.selectedObject;
      this.v_detail.v_stock_no = v.stock_no;
      this.v_detail.v_mileage = v.mileage;
      this.v_detail.v_ext_color = v.ext_color;
      this.v_detail.v_int_color = v.int_color;
      this.v_detail.v_price = v.price;
      this.v_detail.v_engine = v.engine;
      this.v_detail.v_description = v.description;
      this.v_detail.v_id = v.id;
      this.v_detail.v_vin = v.vin;
      this.v_detail.v_year = v.year;
      this.v_detail.v_make = v.make;
      this.v_detail.v_model = v.model;

      if (
        v.imagelist != "" &&
        v.imagelist != undefined &&
        v.imagelist != null &&
        v.imagelist != "undefined"
      ) {
        var imgs = v.imagelist.split(",");
        this.v_detail.v_image = imgs[0];
      }
      $("#addvehicleModal").modal("hide");
      $("#vehicleDetailModal").modal("show");
    },
    updateVOI(veh) {
      //console.log(veh)
      axios
        .post("update_voi/" + this.lead.l_id, veh, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            $("#vehicleDetailModal").modal("hide");
            this.$root.$emit("action", "update_voi", res.data.vid);
            this.lead.l_vehicle_id = res.data.vid;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  mounted() {
    //message
    $(".msgsct").click(function () {
      $(".msgsct").removeClass("active");
      $(this).addClass("active");
    });

    $(".chat-icon").click(function () {
      $(".chatbox-area").removeClass("showbox");
    });

    $(".autocomplete__results").on(
      "click",
      ".autocomplete_no_results",
      function () {
        $("#addvehicleModal").modal("hide");
        $("#addVehicle").modal("show");
        $(".autocomplete_no_results").remove();
      }
    );
    //for increment engagment
    window.Echo.channel("SmsChannel") //Should be Channel Name
      .listen("NewMessageEvent", (e) => {
        console.log(e);
        if (e.type == "permission_change") {
          this.lead.c_sms_permission = 1;
          // this.getmessages(this.lead);
          this.getNewleads();
        } else if (e.type == "increase_engagement") {
          this.$store.commit(
            "updateResponseCount",
            this.$store.state.engagements + 1
          );
        }
      });
    // for  incomming sms
    window.Echo.channel("notiChannel") //Should be Channel Name
      .listen("NotiEvent", (e) => {
        if (
          e.action.email == this.$storage.get("auth").user.email &&
          e.action.type == "incommingText"
        ) {
          this.getSMS();
        }
      });
  },
};
</script>
<style>
.chatbox-area {
  height: 100% !important;
  min-height: 100% !important;
}
.chatbox {
  height: 90vh !important;
  min-height: 90vh !important;
}
.listbottom p span {
  background: red;
  padding: 0px 7px;
  border-radius: 10px;
  color: #fff;
}
.smssend {
  cursor: pointer;
  color: white;
}
.smssend {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  border: 1px solid #3151a1;
  background: #3151a1;
  padding: 8px 20px;
  border-radius: 10px;
  text-transform: capitalize;
  line-height: 1;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
}
.message-content ul li.sender {
  width: 100%;
}
.msg-body {
  height: 30%;
}
.msg-body ul li.repaly span {
  font-weight: normal;
  font-size: 12px;
  line-height: 2;
  color: #7d7d7d;
}
.msg-body ul li.sender span {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 2 !important;
  color: #7d7d7d !important;
  display: table-cell;
}
.car-img {
  width: 358px;
}
.join {
  margin: 0px auto;
  width: 50px;
}
.lightbox__thumbnail img {
  width: 200px;
}
.lightbox {
  z-index: 99999 !important;
}
.credit {
  cursor: pointer;
}
.send-box {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
@media (max-width: 768px) {
  .chatbox,
  .message-area {
    height: 100% !important;
    min-height: 100% !important;
  }
  #CommunicationSmsSection,
  CommunicationFBSection {
    height: 35% !important;
  }
  .send-box {
    position: absolute;
    bottom: 150px;
    width: 100%;
  }
}
</style>



